d<template>
  <div class="login" id="login_container">
    <img
      src="~images/home/logo.png"
      class="login-logo"
      alt=""
      @click="goHome"
    />
    <div class="login-right-box flex">
      <div class="account-wrapper" style="height:auto">
        <div class="flex-center back">
          <div class="flex-center" @click.stop="$router.go(-1)">
            <img src="~image/login/back.png" alt="" />
            <div class="flex-center back-name">
              返回
            </div>
          </div>
          <!-- <div @click.stop="changeCodeLogin" class="login-code-text">
              {{ isPhoneLogin ? "邮箱修改" : "手机修改" }}
            </div> -->
        </div>

        <div class="register-title">
          忘记密码
        </div>
        <el-form
          :model="registerForm"
          status-icon
          :rules="registerRules"
          ref="form"
          label-width="100px"
          class="flex"
        >
          <el-form-item class="flex" prop="account">
            <el-input
              v-model="registerForm.account"
              placeholder="请输入手机号码或邮箱"
            >
              <div slot="prefix" class="flex input-icon">
                <img src="~image/login/account.png" alt="" />
              </div>
            </el-input>
          </el-form-item>
          <el-form-item class="code-box" prop="code">
            <el-input
              maxlength="6"
              v-model="registerForm.code"
              autocomplete="off"
              placeholder="请输入验证码"
            >
              <div slot="prefix" class="flex input-icon">
                <img src="~image/login/code.png" alt="" />
              </div>
            </el-input>
            <el-button
              type="primary"
              class="flex send-code"
              @click="sendCode"
              :disabled="disableBtn"
              native-type="button"
              >{{ btnTxt }}</el-button
            >
          </el-form-item>
          <el-form-item class="flex" prop="newPwd">
            <el-input
              v-model="registerForm.newPwd"
              placeholder="请输入新密码"
              :type="pwdType"
              autocomplete="off"
            >
              <div slot="prefix" class="flex input-icon">
                <img src="~image/login/pwd.png" alt="" />
              </div>
              <div
                class="flex-center"
                slot="suffix"
                @click.stop="changePwd('isPwd')"
              >
                <i class="el-icon-view"></i>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item class="flex" prop="confirmPwd">
            <el-input
              v-model="registerForm.confirmPwd"
              placeholder="请再次确定密码"
              :type="newPwdType"
              autocomplete="off"
            >
              <div slot="prefix" class="flex input-icon">
                <img src="~image/login/pwd.png" alt="" />
              </div>
              <div
                class="flex-center"
                slot="suffix"
                @click.stop="changePwd('isNewPwd')"
              >
                <i class="el-icon-view"></i>
              </div>
            </el-input>
          </el-form-item>

          <el-form-item class="login-btn-box" style="margin-top:0;">
            <div class="color_red regist-desc">
              密码由6-30位的英文字母、数字和特殊字符组成（必须包含数字、小写字母和大写字母）；强度高的密码可提高账号的安全性。
            </div>
            <el-button type="primary" @click="register('registerForm')"
              >确认修改</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import { getPhoneExist } from "@/api/user";
import { get, post, put } from "@/api/index";
import { validatEmail, isvalidPhone, validatePwd } from "@/utils/validate";
export default {
  name: "loign",
  components: {},
  data() {
    const validMail = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号码或邮箱不能为空"));
      } else {
        if (isvalidPhone(value) || validatEmail(value)) {
          this.setFormType(value);
          callback();
        } else {
          callback(new Error("请输入正确格式手机号码或邮箱"));
        }
      }
    };
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码"));
      } else {
        if (validatePwd(value)) callback();
        else callback(new Error("请输入符合规格密码!!!"));
      }
    };
    const confirmPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("确认密码不能为空"));
      } else if (this.registerForm.newPwd !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loginList: [
        require("images/home/login_banner.jpg"),
        require("images/home/login_banner1.jpg")
      ],
      // 修改密码
      // 发送验证码
      disableBtn: false,
      countdown: 60,
      timer: null,
      registerForm: this.resetForm(),
      registerRules: {
        // userName: [
        //   { required: true, message: "请输入用户昵称", trigger: "blur" }
        // ],
        account: [
          { required: true, message: "请输入手机号码或邮箱", trigger: "blur" },
          { required: true, validator: validMail, trigger: "blur" }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newPwd: [{ required: true, validator: validatePass, trigger: "input" }],
        confirmPwd: [
          { required: true, validator: confirmPass, trigger: "input" }
        ]
      },
      redirect: null,
      code: null,
      state: null,
      isPwd: true,
      isNewPwd: true,
      isPhoneLogin: true,
      checkUrl: "",
      codeUrl: "",
      postUrl: ""
    };
  },

  created() {},
  computed: {
    btnTxt() {
      if (this.countdown == 60 || this.countdown == 0) return "发送验证码";
      else return `${this.countdown}秒后发送`;
    },
    pwdType() {
      return this.isPwd ? "password" : "text";
    },
    newPwdType() {
      return this.isNewPwd ? "password" : "text";
    },
    codeText() {
      return this.isPhoneLogin ? "请输入手机号" : "请输入邮箱";
    }
    // checkUrl() {
    //   return this.isPhoneLogin ? "/auth/phone/exist/" : "/auth/email/exist/";
    // },
    // codeUrl() {
    //   return this.isPhoneLogin ? "/auth/phone/send/" : "/auth/email/send/";
    // },
    // postUrl() {
    //   return this.isPhoneLogin ? "/auth/phone/edit-pwd" : "/auth/email/reset";
    // }
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    // 密码明文
    changePwd(type = "isPwd") {
      this[type] = !this[type];
    },
    goHome() {
      this.registerForm = this.resetForm();
      this.$router.replace("/");
    },
    changeCodeLogin() {
      if (!this.timer && this.countdown == 60) {
        this.isPhoneLogin = !this.isPhoneLogin;
        this.$refs.form.clearValidate();
        this.registerForm = this.resetForm();
        return;
      } else this.$message.warning("验证码发送中，请勿切换登录方式");
    },
    checkAcount(value) {
      if (!value) {
        this.$message({
          message: "请输入手机号码或邮箱",
          duration: 3 * 1000
        });
        return false;
      } else {
        if (validatEmail(value) || isvalidPhone(value)) {
          this.setFormType(value);
          return true;
        } else {
          this.$message({
            message: "请输入正确格式手机号码或邮箱",
            duration: 3 * 1000
          });
          return false;
        }
      }
    },
    setFormType(value) {
      this.checkUrl = isvalidPhone(value)
        ? "/auth/phone/exist/"
        : "/auth/email/exist/";
      this.codeUrl = isvalidPhone(value)
        ? "/auth/phone/send/"
        : "/auth/email/send/";
      this.postUrl = isvalidPhone(value)
        ? "/auth/phone/edit-pwd"
        : "/auth/email/reset";
      this.isPhoneLogin = isvalidPhone(value) ? true : false;
      console.log(
        "sendCode",
        this.checkUrl,
        this.codeUrl,
        this.postUrl,
        this.isPhoneLogin
      );
    },
    // 发送修改密码验证码
    sendCode() {
      if (this.checkAcount(this.registerForm.account)) {
        get(this.checkUrl + this.registerForm.account).then(res => {
          if (res.data) {
            post(this.codeUrl + this.registerForm.account)
              .then(response => {
                if (response && response.code == 200 && response.data) {
                  this.disableBtn = true;
                  this.countdown = 59;
                  this.$message.success("验证码发送成功");
                  if (!this.timer) {
                    this.timer = setInterval(() => {
                      // 判断剩余秒数
                      console.log("sendCode", this.countdown);
                      if (this.countdown == 0) {
                        // 清除定时器和复原按钮
                        clearInterval(this.timer);
                        this.disableBtn = false;
                      } else {
                        this.countdown--;
                      }
                    }, 1000);
                  }
                } else this.$message.success("验证码发送失败");
              })
              .catch(err => {
                console.log("sendCode", err);
              });
          } else {
            let text = this.isPhoneLogin ? "该手机号不存在" : "该邮箱不存在";
            this.$message.warning(text);
          }
        });
      }
    },
    // 修改密码
    register(formName) {
      this.validateForm((valid, msg) => {
        if (valid) {
          let data = {
            code: this.registerForm.code,
            password: this.registerForm.newPwd,
            confirmPassword: this.registerForm.confirmPwd
          };
          if (this.isPhoneLogin) data.phone = this.registerForm.account;
          else data.email = this.registerForm.account;
          if (this.isPhoneLogin)
            put(this.postUrl, data)
              .then(res => {
                if (res) {
                  this.$message.success("密码修改成功");
                  this.$store.dispatch("user/loginOut");
                  this.registerForm = this.resetForm();
                }
              })
              .catch(err => {
                // this.$message.warning(err.message);
              });
          else
            post(this.postUrl, data)
              .then(res => {
                if (res) {
                  this.$message.success("密码修改成功");
                  this.$store.dispatch("user/loginOut");
                  this.registerForm = this.resetForm();
                }
              })
              .catch(err => {
                // this.$message.warning(err.message);
              });
        } else {
          this.$message.warning(msg[0].message);
        }
      });
    },
    validateForm(call, formName = "form") {
      const promises = [this.$refs[formName]];
      console.log("promises", promises, this.$refs[formName]);
      let count = 0;
      for (let index = 0; index < promises.length; index++) {
        const element = promises[index];
        element.validate((result, errors) => {
          if (result) {
            count++;
            if (count == promises.length) {
              call(true);
              return;
            }
          } else {
            const fkey = Object.keys(errors)[0];
            call(false, errors[fkey]);
            return;
          }
        });
      }
    },
    resetForm() {
      return {
        account: "",
        code: "",
        newPwd: "",
        confirmPwd: ""
      };
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.disableBtn = false;
      this.timer = null;
      this.countdown = 60;
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/login'

.login
  position relative
  .back
    margin 0px 0 16px 0
    height 24px
    font-size 14px
    color #fff
    font-weight 500
    justify-content space-between
    cursor pointer

    .back-name
      height 100%
    .login-code-text
      font-size 16px
      color #fff
      cursor pointer

    img
      width 24px
      height 24px
      margin-right 8px

  .register-title
    margin-bottom 36px
    font-size 22px
    color #fff
    font-weight 700
    text-align center

  .regist-desc
    margin-bottom 40px
    font-size 14px
    color #fff

  .login-logo
    width 226px
    height 40px
    margin 20px 0 24px 60px
    cursor pointer

  .login-left-img
    position absolute
    top 84px
    left 0
    width 100%
    height 601px

 

 

  .goHome
    position absolute
    top 23px
    left 20px
    font-size 38px
    color #fff
    cursor pointer

.login-register
  display flex
  justify-content flex-start

.login-icon-box
  display flex
  align-items center
  justify-content center
  color #fff

  .flex-columm
    cursor pointer

  img
    width 32px

.wx-login-box
  height 400px
iframe
  position: fixed
  top 50%
  left 50%
  width 350px
  height 400px
  background #fff
  overflow hidden
  transform translate(-50%,-50%)
  z-index 100
</style>
